<template>
  <div :class="tStyle.menuWrap">
    <div :class="tStyle.menuInner">
      <button :class="tStyle.logo" @click="goMainPage">
        <img src="@/assets/logo.png" alt />
      </button>
      <button
        :class="tStyle.sideMenuBtn"
        @click="MainStore.sideMenu = true"
        v-if="sideMenuCheck"
      >
        <img src="@/assets/side_menu.png" alt class="w-full" />
      </button>
      <button v-else class="opacity-0"></button>
      <div :class="tStyle.menuRight">
        <div v-if="!CommonStore.isLogin">
          <p :class="tStyle.pcLogin">
            {{ message.menuBar.text.loginText[language] }}
          </p>
          <p :class="tStyle.mobileLogin">
            {{ message.menuBar.text.loginText2[language] }}
          </p>
        </div>

        <div v-else :class="tStyle.LoginWrap">
          <p :class="tStyle.user">
            <span
              :class="tStyle.code"
              v-if="CommonStore.userInfo?.userCate !== ''"
              >[{{
                CommonStore.userInfo?.userCate === 'U'
                  ? message.join.joinType.typeList[0].title[language]
                  : CommonStore.userInfo?.userCate === 'B'
                  ? message.join.joinType.typeList[1].title[language]
                  : ''
              }}]</span
            >
            <span v-if="CommonStore.userInfo?.firstName !== ''">
              {{ CommonStore.userInfo?.lastName
              }}{{ CommonStore.userInfo?.firstName
              }}{{ message.login.sir[language] }},
            </span>
            {{ message.login.hello2[language] }} {{ ':)' }}
          </p>
          <div :class="tStyle.line"></div>
          <p :class="tStyle.pcBtn" @click="qrModalOpen">
            {{ message.myPage.myPage.qr[language] }}
          </p>
          <div :class="tStyle.line"></div>
          <p :class="tStyle.pcBtn" @click="pwModalOpen">
            {{ message.myPage.myPage.title[language] }}
          </p>
          <div :class="tStyle.line"></div>
          <p :class="tStyle.pcBtn" @click="logout">
            {{ message.login.logout[language] }}
          </p>
          <div :class="tStyle.timeWrap">
            <p :class="tStyle.time">{{ loginTimeMin }} : {{ loginTimeSec }}</p>
            <p
              :class="tStyle.extend"
              @click="CommonStore.loginTimeCount = 1800"
            >
              {{ message.login.extend[language] }}
            </p>
          </div>
        </div>

        <ul :class="tStyle.lang">
          <li class="cursor-pointer" @click="handleLang">
            {{ CommonStore.language.toUpperCase() }}
          </li>
        </ul>
      </div>
    </div>
    <SideMenu v-show="MainStore.sideMenu" :language="language" />
    <QrCode v-if="MainStore.qrModal.show" />
    <PwAuth v-if="MainStore.pwModal.show" />
  </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Request from '@/utils/api/Request';
import getLog from '@/utils/getLog';

import message from '@/lib/message.json';
import { renderText } from '@/utils/renderText';
import { rootStore } from '@/store/rootStore';
import SideMenu from '../../main/views/SideMenu.vue';
import BaseModal from './BaseModal.vue';
import QrCode from '../../main/components/QrCode.vue';
import PwAuth from '../../main/components/PwAuth.vue';

const CommonStore = reactive(rootStore?.commonStore());
const MainStore = reactive(rootStore?.mainStore());

const language = ref(rootStore?.commonStore().language);
const router = useRouter();
const route = useRoute();

const logout = () => {
  CommonStore.setLogOut();
  router.push('/login');
};

const handleLang = () => {
  const local = localStorage.getItem('kjlang');
  if (local === null || language.value === 'kr') {
    localStorage.setItem('kjlang', 'jp');
  } else {
    localStorage.setItem('kjlang', 'kr');
  }
  window.location.reload();
};

const goMainPage = () => {
  if (route.path !== '/login') {
    router.push('/');
  }
};

const sideMenuCheck = computed(() => {
  if (!CommonStore.isLogin) return false;
  else if (route.path === '/login') return false;
  else if (route.path.includes('/join/')) return false;
  else {
    return true;
  }
});

const loginTimeMin = computed(() => {
  return parseInt(CommonStore.loginTimeCount / 60);
});

const loginTimeSec = computed(() => {
  return String(CommonStore.loginTimeCount % 60).padStart(2, '0');
});


const qrModalOpen = (value) => {
  MainStore.setModalControl('show', value, 'qrModal');
};

const pwModalOpen = (value) => {
  MainStore.setModalControl('show', value, 'pwModal');
};

const tStyle = ref({
  logo: 'mobile:hidden',
  sideMenuBtn: 'pc:hidden mobile:block mobile:w-[2.24rem]',
  pcLogin: 'mobile:hidden text-[18px]',
  mobileLogin: 'pc:hidden mobile:block text-[1.3rem]',
  menuWrap: 'fixed w-full z-[30] bg-[#95C439]',
  menuInner:
    'flex justify-between items-center max-w-[1792px] px-[24px] w-full mx-auto pc:h-[64px] mobile:h-[5rem]',
  menuRight:
    'flex items-center text-[18px] gap-x-[20px] mobile:gap-x-[8px] text-white',
  lang: 'px-[16px] py-[5px] mobile:px-[12px] mobile:py-[4px] border rounded-full font-bold mobile:text-[1.2rem]',
  LoginWrap: 'flex items-center gap-x-[14px] text-[14px] font-medium',
  user: 'mobile:hidden',
  code: 'font-bold',
  line: 'h-[16px] w-[1px] bg-white mobile:hidden',
  pcBtn: 'mobile:hidden cursor-pointer',
  timeWrap:
    'rounded-full grid grid-cols-2 overflow-hidden font-bold text-center text-[14px] pc:w-[140px] mobile:w-[15rem] mobile:text-[1.2rem]',
  time: 'bg-white text-[#95C439] py-[6px] mobile:py-[4px] px-[10px]',
  extend:
    'bg-white bg-opacity-20 py-[6px] mobile:py-[4px] px-[10px] cursor-pointer',
  modalTitle:
    'text-[#222] text-[16px] font-semibold tracking-[-0.4px] mobile:text-[1.6rem]',
  subTitle:
    'text-[#646464] font-medium tracking-[-0.32px] mobile:text-[1.6rem] pc:pb-[16px] mobile:pb-[1.6rem]',
  findBtn:
    'text-[#fff] rounded-[4px] bg-key py-[9px] px-[12px] mobile:text-[1.4rem] w-[132px]',
  inter: 'text-key font-bold pc:text-[32px] mobile:text-[3.2rem] py-[16px]'
});
</script>
<style scoped></style>
