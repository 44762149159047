import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultLanguage = 'jp';

const state = {
  inAccess: false,
  isMobile: false,
  language: defaultLanguage,
  isLogin: false,
  userInfo: {
    RankId: '',
    changeAccountInfoYn: '',
    enFname: '',
    enLname: '',
    firstName: '',
    furiFname: '',
    furiLname: '',
    joinState: '',
    lastName: '',
    llpJoinYn: '',
    owJoinYn: '',
    userBm: '',
    userCate: '',
    userCode: '',
    userInfoSeq: ''
  },
  loginTimeCount: 1800,
  timeoutModal: false,
  interval: null,
  errMsg: {
    error: '',
    message: ''
  },
  errState: false,
  documentModal: {
    show: false
  },
  goLoginPage: false,
  currentPath: ''
};

const commonStore = (set) => ({
  ...state,
  setMobile(value) {
    set(() => ({
      isMobile: value
    }));
  },
  setIsLogin(value) {
    set(() => ({
      isLogin: value
    }));
  },
  setUserInfo(value) {
    set((state) => {
      return {
        userInfo: {
          ...state.userInfo,
          ...value
        }
      };
    });
  },
  setLogOut() {
    localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_KEY);
    set(() => ({
      isLogin: false,
      userInfo: {}
    }));
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  setErrorData(value) {
    set(() => {
      return {
        errMsg: value
      };
    });
  },
  setModalControl(key, value, modalType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value
        }
      };
    });
  },
  setInAccess() {
    set(() => ({
      inAccess: true
    }));
  }
});

export default create(createStore(commonStore));
