<template>
  <BaseModal
    @onClose="MainStore.setModalControl('show', false, 'qrModal')"
    :btnText="messages.myPage.auth.close[language]"
    :width="400"
    :padding="CommonStore.isMobile ? '2.2rem 1.5rem' : '32px 0'"
  >
    <template v-slot:modalContent>
      <div :class="tStyle.modalWrap">
        <h3 :class="tStyle.title">{{ message.modal.qr[language] }}</h3>
        <!-- <img :src="CommonStore.userInfo.qrUrl" alt="" :class="tStyle.qrImg" /> -->
        <qrcode-vue
          :value="CommonStore.userInfo?.userCode"
          :size="200"
          level="H"
          :class="tStyle.qrImg"
        />
      </div>
    </template>
  </BaseModal>
</template>
<script setup>
import { reactive, ref, onMounted } from 'vue';
import BaseModal from '@/feature/common/components/BaseModal.vue';
import QrcodeVue from 'qrcode.vue';
import message from '@/lib/message.json';

import Request from '@/utils/api/Request';
import getLog from '@/utils/getLog';

import { rootStore } from '@/store/rootStore';
const JoinStore = reactive(rootStore?.joinStore());
const MainStore = reactive(rootStore?.mainStore());
const CommonStore = reactive(rootStore?.commonStore());

const checkedSeq = ref(null);

const language = ref(rootStore?.commonStore().language);

const messages = ref(message);

const radioCheck = true;

const checkSelect = (idx) => {
  checkedSeq.value = idx;
};

const addrWriteBtn = () => {
  if (JoinStore.addrModal.type === 'user') {
    JoinStore.joinInputData.userData.zipCode =
      JoinStore.addrList[checkedSeq.value].zipcode;
    JoinStore.joinInputData.userData.addr =
      JoinStore.addrList[checkedSeq.value].result;
  } else if (JoinStore.addrModal.type === 'send') {
    JoinStore.joinInputData.userData.recvZipCode =
      JoinStore.addrList[checkedSeq.value].zipcode;
    JoinStore.joinInputData.userData.recvAddr =
      JoinStore.addrList[checkedSeq.value].result;
  }
  MainStore.setModalControl('show', false, 'qrModal');
};

const tStyle = ref({
  modalWrap: 'text-center',
  title: 'text-[20px] font-semibold tracking-[-0.48px]',
  qrImg: 'my-[24px] mx-auto border-4 p-4  border-[#000]'
});
</script>
